<template>
  <div class="reward-disclaimer">
    <BaseText size="subheadline-large">
      {{ $t('rewards.terms_and_conditions') }}
    </BaseText>
    <Markdown
      class="my-s24"
      :content="disclaimer"
    />
    <ButtonV2
      @onClick="hideModal"
      class="mt-auto"
      :label="$t('navigation.close')"
      testId="btn-close"
      wide
    />
  </div>
</template>

<script>
import { BaseText, ButtonV2, Markdown } from '@/components/misc';

export default {
  name: 'RewardDisclaimer',
  components: {
    BaseText,
    ButtonV2,
    Markdown,
  },

  data() {
    return {
      isLoading: false,
    };
  },
 
  props: {
    disclaimer: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.reward-disclaimer {
  @apply flex flex-col p-s4;
  max-width: 350px;
}
</style>
